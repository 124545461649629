@import './var.scss';
@import "./mixin.scss";

.property {
    box-shadow: 0px 0px 10px 0px #57585A1A;
    background-color: white;
    overflow: hidden;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    display: inline-block;
    height: 100%;
    position: relative;
    cursor: pointer;
    margin-bottom: 4vw;

    &:hover {
        box-shadow: 0px 0px 10px 0px $naranja;

        .btnTarjetaP {
            background-color: $naranja;
            color: white;
        }
    }
}

.link {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
}

.containerBtn {
    width: 100%;
    position: absolute;
    bottom: 1vw;
    left: 0;
}

.wpImg {
    position: relative;
}

.tags {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 1rem;
}

.tag {
    position: absolute;
    padding: 0.5rem;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;

    &:nth-child(1) {
        top: 1rem;
        left: 1rem;
    }

    &:nth-child(2) {
        top: 1rem;
        right: 1rem;
        color: black;
    }
}

.tagSubsidio {
    position: absolute;
    padding: 0.5rem;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    top: 1rem;
    right: 1rem;
}

.tagImg {
    width: 20%;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
}

.atributo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $naranja;
    z-index: 9;

    /* font-size: 1.5rem; */
    /* width: 1.7rem; */
    /* height: 1.5rem; */
    img {
        width: auto !important;
        height: 1.5rem;
    }
}

.bgVendido {
    background-color: rgba(white, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgVendido .tag {

    &:nth-child(2) {
        top: 1rem;
        left: 1rem;
        right: initial;
    }

    &:nth-child(3) {
        top: 1rem;
        right: 1rem;
        color: black;
    }
}


.tagVendido {
    width: 50%;
}

.tarjetaPagos {
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include toAnim(0.3s);
}

.tarjetaPagos .input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.contenidoPago {
    position: relative;
    z-index: 1;
    @include toAnim(0.3s);
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    background-color: white;
    @include toAnim(0.3s);
}

/* On mouse-over, add a grey background color */
.tarjetaPagos:hover .input~.checkmark {
    background-color: #ccc;
}

.tarjetaPagos .input:checked~.checkmark {
    background-color: $naranja;
}

.contenidoPago.active {
    .titulo {
        color: white;
    }
}

.roundBottom {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    cursor: pointer;

    .textPrimary {
        color: $gris;
    }

    .textSengundary {
        color: $naranja;
    }
}

.roundBottom.active {

    .textPrimary {
        color: white;
    }

    .textSengundary {
        color: white;
        ;
    }
}

.position_tooltip {
    position: relative;
    z-index: 999;
    line-height: 100%;

    .icon_tooltip {
        font-size: 0.9vw;
        margin-left: 0.4vw;
    }
}
@media (min-width: 1920px) {

    .position_tooltip {    
        .icon_tooltip {
            font-size: 0.9rem;
            margin-left: 0.4rem;
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .property {
        margin-bottom: 4rem;
    }

    .containerBtn {
        bottom: 1rem;

    }

    .position_tooltip {    
        .icon_tooltip {
            font-size: 0.9rem;
            margin-left: 0.4rem;
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .property {
        margin-bottom: 3rem;
    }

    .containerBtn {
        bottom: 1rem;

    }
    .position_tooltip {    
        .icon_tooltip {
            font-size: 0.9rem;
            margin-left: 0.4rem;
        }
    }
}

// tablet MD
@media (max-width: 767px) {
    .property {
        margin-bottom: 4.5rem;
    }

    .containerBtn {
        bottom: 1rem;

    }

    .position_tooltip {    
        .icon_tooltip {
            font-size: 0.9rem;
            margin-left: 0.4rem;
        }
    }
}